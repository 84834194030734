import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouteComponentProps } from "react-router";
import { validatePassword,vaidatorList } from "../../../components/src/Utils.web";
// Customizable Area End
export const configJSON = require("./config");
export type Props = RouteComponentProps & {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  setError:any;
  setSuccess:any;
  userType:any;
  setUserType:any;
  setUserProfile:any;
  // Customizable Area End
}

// Customizable Area Start
type field = {
  value: string;
  error: string;
  valid?: boolean;
}

export enum userType {
  darkStoreUser = "darkStoreUser",
  globalStoreUser = "globalStoreUser"
}
// Customizable Area End
interface S {
  // Customizable Area Start
  signupType: userType;
  email:field;
  password: field;
  passwordVisibility:boolean;
  passwordValidations: any;
  error: any; 
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class LoginPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postLoginApiCallId: any;
  getUserProfileCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
       // Customizable Area Start
      signupType: userType.globalStoreUser,
      password: {
        value:"",
        error:"",
        valid: false
      },
      email:{
        value:"",
        error:""
      },
      passwordVisibility:false,
      passwordValidations:vaidatorList,
      error:""
       // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.setState({signupType: this.props.userType, email:{value:"", error:""}, password:{value:"", error:""}});
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
    
      if(responseJson){
        if(responseJson.errors){
            this.props.setError(responseJson.errors[0].failed_login);
        }else if(responseJson.status === 500){
          this.props.setError("Internal Server Error");
          return;
        }else{
          await this.handleLoginSuccessCallbacks(apiRequestCallId, responseJson);
        }
      }

      if(errorReponse){
        this.props.setError(errorReponse);
      }

    }
    // Customizable Area End
  }

  loginApiCall = (data:any) => {
    const { contentType, method, endPoint, body, extraHeaders } = data;
    const header = {
      "Content-Type": contentType,
      ...extraHeaders
    };
    const loginReqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    loginReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    loginReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    loginReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

      loginReqMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(loginReqMessage.id, loginReqMessage);
    return loginReqMessage.messageId;
  }

  handleLoginSuccessCallbacks = async(apiRequestCallId: any,responseJson: any) => {
    switch(apiRequestCallId){

      case this.postLoginApiCallId : {
        await this.handlePostLoginSuccessCallback(responseJson);
        break;
      }

      case this.getUserProfileCallId: {
        this.handleGetUserProfileSuccessCallback(responseJson);
        break;
      }

      default :{
        break;
      }
    }
  }

  handleGetUserProfileSuccessCallback = (res: any) => {
    this.props.setUserProfile(res.data);
  }

  handlePostLoginSuccessCallback = async (res: any) => {
    if(res.meta){
      localStorage.setItem("token",res.meta.token);
      localStorage.setItem("userType",this.props.userType);
      this.props.setSuccess("You have logged in successfully!")
      await this.fetchUserProfile(res.meta.token);
     
        if(this.state.signupType === userType.globalStoreUser){
          
          this.props.history.replace("/dark_store_management");
        }else{
          this.props.history.replace("/orders");
        }
    }
  }

  fetchUserProfile = async (token:any) => {
    this.getUserProfileCallId = this.loginApiCall({
      contentType: configJSON.getUserProfileApiContentType,
      method: configJSON.getUserProfileApiMethodType,
      endPoint: configJSON.getUserProfileApiEndPoint,
      extraHeaders: {
        token
      }
    });
  }

  handleChangeSignupType = (e:any, val:any) => {
    this.props.setUserType(val === userType.darkStoreUser ? "darkStoreUser": "globalStoreUser");
    this.setState({signupType: val, email: {value:"", error:""}, password:{value:"", error:""}});
  }

  passwordFieldHandler = (e:any) => {
    let { passwordValidations, isValidPassword } = validatePassword(e.target.value, this.state.passwordValidations);
    this.setState({
      password: {
      ...this.state.password,
      value: e.target.value,
      error: "" ,
      valid: isValidPassword
      },
      passwordValidations
    })
  }

  emailFieldHandler = (e:any) => {
    this.setState({email: {
      ...this.state.email,
      value: e.target.value,
      error: ""
    }})
  }

  handlePasswordVisibility = () => {
    this.setState({passwordVisibility: !this.state.passwordVisibility});
  }

  loginHandler = () => {

    const { email,password } = this.validateFields();
   
    if(!!!email.error && !!!password.error){
      let apiBody = {
        data:{
          attributes: {
              email: email.value.toLowerCase(),
              password: password.value,
          },
          type: "email_account"
        }
      };

      this.postLoginApiCallId = this.loginApiCall({
        contentType: configJSON.loginApiContentType,
        method: configJSON.loginApiMethodType,
        endPoint: configJSON.loginApiEndPoint,
        body: apiBody,
      });
    }
  }


  validateFields = () => {
    let { email, password, signupType } = this.state;

    let updatedEmail = email.value;
    if(signupType === userType.globalStoreUser){
      updatedEmail = updatedEmail + "@docilekart.com";
    }

    if(email.value.trim() === ""){
      email.error = configJSON.emptyEmailErrorText;
    }else {
      let validEmailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
      if(!(validEmailRegex.test(updatedEmail.trim()))){
        email.error = configJSON.invalidEmailErrorText;
      }
    }
    
    if(password.value.trim() === "" || !password.valid){
      password.error = configJSON.invalidPasswordErrorText;
    }
    
    this.setState({email: {...email}, password: {...password}});
    return { email: {...email, value: updatedEmail}, password }
  }
  // Customizable Area End
}
