import React from "react";
// Customizable Area Start
import DeliveryChallanOrderStatusController, {Props} from "./DeliveryChallanOrderStatusController.web";
import { withStyles} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import GlobalStoreDashboardLayout from "../../../components/src/GlobalStoreDashboardLayout.web";
import OutboundOrderDetail  from "../../../components/src/OutboundOrderDetail.web";
import NoAccessPage from "../../../components/src/NoAccessPage.web";

const styles : any = {
    selectInput:{
        "& .MuiOutlinedInput-input":{
            padding: "10px 32px 10px",
            width:"200px"
        }
    },
    tableMargin:{
        marginTop:"1rem"
    },
    datePickerConatiner:{
        display:"flex",
        flexDirection:"column",
        gap:"10px"
    },
    inputLabel:{
        fontSize:"14px",
        fontWeight:500,
        fontFamily:"gilroy-medium"
    },
    deleteItem:{
        fontWeight:500,
        color:"red",
        textDecoration: "underline",
        cursor:"pointer"
    }

}


class DeliveryChallanOrderStatus extends DeliveryChallanOrderStatusController{
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { classes, ...restProps } = this.props;
        const { userProfile } = restProps;
        const groups = userProfile?.attributes?.groups?.data?.length > 0 ? userProfile?.attributes?.groups?.data[0].attributes.settings : {};

        let renderComponent;

        if (!groups?.delivery_challan) {
            renderComponent = <NoAccessPage description="Sorry, you don't have the permission" />;
        } else if (this.state.orderDetails && this.state.orderDetails?.attributes.catalogues.length) {
            renderComponent = (
                <OutboundOrderDetail
                    anchorEl={this.state.anchorEl}
                    handleClick={this.handleClick}
                    handleClose={this.handleClose}
                    itemList={[]}
                    orderDetails={this.state.orderDetails}
                    globalStore={groups.delivery_challan}
                    handleUpdateStatus={this.updateOrderStatus}
                />
            );
        } else {
            renderComponent = <NoAccessPage description="Something went wrong" />;
        }

        return(
            <GlobalStoreDashboardLayout classes={classes} {...restProps} headerTitle="Delivery Challan" actionHandler={undefined} headerAction="" subTitle="" hasAccess = {groups.delivery_challan}>
                {renderComponent}
            </GlobalStoreDashboardLayout>
        )
        // Customizable Area End
    }
}

export { DeliveryChallanOrderStatus };
export default withStyles(styles)(withRouter(DeliveryChallanOrderStatus));
 // Customizable Area End