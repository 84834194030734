import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import { RouteComponentProps } from "react-router";

import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import {  ICatalogueItemInterface, IHeaderInterface, IPurchaseOrderAddFromList, IPurchaseOrderCatalogue, SupplierDetailsInterface } from "./types";
// Customizable Area End

export const configJSON = require("./config");
export const rows = configJSON.purchaseOrdersRows;
interface SS {
  id: any;
}
interface S {
  // Customizable Area Start
  openStatusMenu : null | HTMLElement,
    openStatusPopup:boolean,
    isCancelled:boolean,
  headerData: IHeaderInterface,
  createPurchaseOrderData: IPurchaseOrderAddFromList;
  catalogueItemList: ICatalogueItemInterface[],
  shipmentAddress: string;
  selectedRowList: number[],
  supplierDetails: SupplierDetailsInterface,
  qrUrl:string,
  expectedDeliveryDate: Date;
  poNumber:string,
  editingPurchaseOrderID : string

  // Customizable Area End
}

export type Props = RouteComponentProps &  {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  userType:any;
  userProfile:any;
  setError:any;
  setUserProfile:any;
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number
  // Customizable Area End
}
export default class PurchaseOrderDetailsFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getItemsApiCallId: string = "";
  getPurchasedOrderDataAPI: string = ""
  confirmPurchaseOrderAPI: string = "";
  inboundedStatusAPIID : string = ""
    
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isCancelled:false,
      openStatusMenu : null,
      openStatusPopup:false,

      catalogueItemList: [],
      poNumber:"",
      qrUrl:"",
      shipmentAddress:"",
      expectedDeliveryDate: new Date(),
      selectedRowList: [],
      supplierDetails: configJSON.supplierObject,
      
      createPurchaseOrderData: configJSON.catalogueListSelectedItems,
      headerData: {
        "Content-Type": configJSON.apiContentType,
        token: localStorage.getItem("token"),
      },
      editingPurchaseOrderID : ""
      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
         if (apiRequestCallId === this.getPurchasedOrderDataAPI) {
          this.setState({
            createPurchaseOrderData: responseJson.data,
            catalogueItemList: responseJson.data?.attributes?.catalogues,
            qrUrl:responseJson.data?.attributes?.qr?.url,
            poNumber:responseJson.data?.attributes.po_number,
            shipmentAddress: responseJson.data.attributes?.shipment_address,
            expectedDeliveryDate: new Date(responseJson.data.attributes?.delivery_date),
            selectedRowList: responseJson.data?.attributes?.catalogues.map((ele: IPurchaseOrderCatalogue) => ele.catalogue_id),
            supplierDetails: {
              id: responseJson.data.attributes.supplier?.id,
              payment_terms:responseJson.data.attributes.payment_terms,
              ...responseJson.data.attributes.supplier?.attributes
            },
            isCancelled : responseJson.data?.attributes?.status === "cancelled" ? true : false
          })
        }
        if (apiRequestCallId === this.confirmPurchaseOrderAPI) {
          if (!responseJson?.data) {
            this.props.setError("Something went wrong");
            this.setState({isCancelled: false, openStatusMenu: null, openStatusPopup:false})
            return;
          }
        if(this.state.isCancelled){
    this.props.history.push(configJSON.purchaseOrderPage);

        }else{
        this.handleUpdateInboundedStatus();
        }
      }
      if (apiRequestCallId === this.inboundedStatusAPIID) {
        this.handleGetPurchasedOrderData(this.state.editingPurchaseOrderID);
        this.props.history.push(configJSON.purchaseOrderPage);
      }
      
         }
  
   
    // Customizable Area End
  }

  async componentDidMount() {
    const url = window.location.href;
    const parts = url.split("/");
    const id: string = parts[parts.length - 1];
    if (url.includes(configJSON.viewPurchaseOrder)) {
      this.handleGetPurchasedOrderData(id);
      this.setState({
        editingPurchaseOrderID: id
      })
    }
    }


  handleGetPurchasedOrderData = (purchaseID: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPurchasedOrderDataAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPurchaseOrderList}/${purchaseID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(this.state.headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleFieldEditChange = ( id: number, value: number | string) => {
    const data = [...this.state.catalogueItemList];
    this.setState({
      catalogueItemList: data.map((ele) => ele.catalogue_id === id ? ({ ...ele, verified_quantity: value }) : ({ ...ele }))
    })
  }

  handleEditPurchaseOrder = (quantityEdited : boolean) => {
    const catalogueDataList = this.state.catalogueItemList.map((ele) =>  ({
      catalogue_id: ele.catalogue_id,
      quantity: ele.quantity || 0,
      unit_purchase_price: ele.unit_purchase_price,
      id: ele.id,
      verified_quantity : ele.verified_quantity || ele.quantity 
    })
      )

    const payload = quantityEdited  ?  {
      purchase_order: {
        purchase_order_catalogues_attributes: catalogueDataList
      }
    } :  {
      purchase_order: {
        status:"cancelled"
      }}

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.confirmPurchaseOrderAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createPurchaseOrderAPIURL}/${this.state.editingPurchaseOrderID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(this.state.headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleUpdateInboundedStatus = () => {
   
    const payload =   {
      purchase_order: {
        status:"inbounded",
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.inboundedStatusAPIID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createPurchaseOrderAPIURL}/${this.state.editingPurchaseOrderID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(this.state.headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
 

  handleOpenStatusMenu = (event: React.MouseEvent<HTMLButtonElement>) =>{
    this.setState({
      openStatusMenu: event.currentTarget
    })
  }
  handleCloseStatusMenu = () =>{
    this.setState({
      openStatusMenu: null
    })
  }

  handleOpenUpdateStatusPopup=()=>{
    this.setState({
      openStatusPopup:true,
      openStatusMenu:null
    })
  }

  handleCloseUpdateStatusPopup=()=>{
    this.setState({
      openStatusPopup:false,
    })
  }

  handleEditOrder=()=>{
    this.handleEditPurchaseOrder(true)
    this.setState({
      openStatusMenu:null,
      openStatusPopup:false
    })
    
  }

  handleCncelledOrder=()=>{
   this.setState({
    isCancelled:true
   })
    this.handleEditPurchaseOrder(false)
  }
  // Customizable Area Start
handleCreateOrder=()=>{
  this.props.history.push(configJSON.createPurchaseOrderURL)
}



  // Customizable Area End
}
