import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";

// Customizable Area Start
import { RouteComponentProps } from "react-router";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
// Customizable Area End
export const configJSON = require("../config");
interface SS {
  id: any;
}
interface S {
  // Customizable Area Start
  inventoryDetails:any
  // Customizable Area End
}
export type Props = RouteComponentProps &  {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any;
  userType:any;
  setError:any;
  userProfile:any;
  setUserProfile:any;
  match:{ params: { id:any } };
  newNotification:boolean
  setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
  setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
  lengthOfNotification:number
  // Customizable Area End
}
export default class InventoryDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDarkstoreInventoryDetailsApiCallId : any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      // Customizable Area Start
      inventoryDetails:null
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let inventoryDetailsRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if(responseJson){
          if(responseJson.status === 500){
            this.props.setError("Internal server error");
          }else if(responseJson.errors){
            this.setState({inventoryDetails: null})
          }else{
           
           if( (inventoryDetailsRequestCallId === this.getDarkstoreInventoryDetailsApiCallId) && responseJson.data){
            this.setState({inventoryDetails: responseJson.data});
           }
          }
        }

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        
        if(errorReponse){
          this.props.setError(errorReponse);
        }
      }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.fetchInventoryDetails()
  }

  inventoryDetailsApiCall = async (data: any) => {
    const { contentType, method, endPoint, body} = data;
    let token = await localStorage.getItem("token");
    const header = {
      "Content-Type": contentType,
      token,
    };
    const inventoryDetailsApiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    inventoryDetailsApiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    inventoryDetailsApiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    inventoryDetailsApiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    inventoryDetailsApiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body) 
    );
    runEngine.sendMessage(inventoryDetailsApiMessage.id, inventoryDetailsApiMessage);
    return inventoryDetailsApiMessage.messageId;
  }

   fetchInventoryDetails = async () => {
    const id = this.props.match.params.id;
    this.getDarkstoreInventoryDetailsApiCallId = await this.inventoryDetailsApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getAPIMethod,
      endPoint: `${configJSON.getInventoryDetails}/${id}`,
    });
  }
  // Customizable Area End
}
