import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { RouteComponentProps } from "react-router";
import {
  validatePassword,
  vaidatorList
} from "../../../components/src/Utils.web";
// Customizable Area End

export const configJSON = require("./config");
export type Props = RouteComponentProps & {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  setError:any;
  setSuccess:any;
  setUserType:any;
  userType:any;
  // Customizable Area End
};

// Customizable Area Start
export enum userType {
  darkStoreUser = "darkStoreUser",
  globalStoreUser = "globalStoreUser"
}

// Customizable Area Start
type field = {
  value: string;
  error: string;
  valid?: boolean;
};

// Customizable Area End

// Customizable Area End
interface S {
  // Customizable Area Start
  signupType: userType;
  email: field;
  password: field;
  passwordVisibility: boolean;
  passwordValidations: any;
  error: any; 
  step: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SignupPageController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postSignupApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      signupType: userType.globalStoreUser,
      passwordVisibility: false,
      password: {
        value: "",
        error: ""
      },
      email: {
        value: "",
        error: ""
      },
      passwordValidations: vaidatorList,
      error:"",
      step:0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  
  async componentDidMount() {
     // Customizable Area Start
     this.setState({password:{value:"", error:""},signupType: this.props.userType, email:{value:"", error:""}, });
     // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log("@@@@ signup response", responseJson);
      if (responseJson) {
        if (responseJson.data) {
          this.props.setSuccess("Your account is created successfully!")
          this.setState({step:1});
        }

        if(responseJson.errors){
          if(responseJson.errors[0].email){
            let updatedEmail = {...this.state.email, error:responseJson.errors[0].email};
            this.setState({email:updatedEmail});
          }
        }
      }

      if (errorReponse) {
        console.log("@@@@ signup errorReponse", errorReponse);
        this.props.setError(errorReponse);
      }
    }
  }

  // Customizable Area Start

  handleNavigateToLoginScreen = () => {
    this.props.history.push("/login");
  }

  signupApiCall = (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postSignupApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChangeSignupType = (e: any, val: any) => {
    this.setState({
      signupType: val,
      email: { value: "", error: "" },
      password: { value: "", error: "" }
    });
    this.props.setUserType(val === userType.darkStoreUser ? "darkStoreUser": "globalStoreUser");
  };

  signupHandler = () => {
    const { email, password } = this.validateFields();
    const { signupType } = this.state;
    if (!!!email.error && !!!password.error) {
      let apiBody = {
        data: {
          attributes: {
            email: email.value.toLowerCase(),
            password: password.value,
            role_id: signupType === userType.globalStoreUser ? 4 : 5,
          },
          type: "email_account"
        }
      };

      this.signupApiCall({
        contentType: configJSON.signupApiContentType,
        method: configJSON.signupApiMethodType,
        endPoint: configJSON.signupApiEndPoint,
        body: apiBody
      });
    }
  };

  validateFields = () => {
    let { email, password, signupType } = this.state;
    let updatedEmail = email.value;
    if (signupType === userType.globalStoreUser) {
      updatedEmail = updatedEmail + "@docilekart.com";
    }

    if (updatedEmail.trim() === "") {
      email.error = configJSON.emptyEmailErrorText;
    } else {
      let validEmailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
      if (!validEmailRegex.test(updatedEmail.trim())) {
        email.error = configJSON.invalidEmailErrorText;
      }
    }

    if (!password.valid || password.value.trim() === "") {
      password.error = configJSON.invalidPasswordErrorText;
    }

    this.setState({ email: { ...email }, password: { ...password } });
    return { email: { ...email, value: updatedEmail }, password };
  };

  backHandler = () => {
    this.props.history.goBack();
  };

  emailFieldHandler = (e: any) => {
    this.setState({
      email: {
        ...this.state.email,
        value: e.target.value,
        error: ""
      }
    });
  };

  passwordFieldHandler = (e: any) => {
    let { passwordValidations, isValidPassword } = validatePassword(
      e.target.value,
      this.state.passwordValidations
    );
    this.setState({
      password: {
        ...this.state.password,
        value: e.target.value,
        error: "",
        valid: isValidPassword
      },
      passwordValidations
    });
  };

  handlePasswordVisibility = () => {
    this.setState({ passwordVisibility: !this.state.passwordVisibility });
  };

  // Customizable Area End
}
