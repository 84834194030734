import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { RouteComponentProps } from "react-router";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Field } from "../../../components/src/types";
import { IDeliveryChallanItem } from "./types";

export const configJSON = require("./config");


interface SS {
    id: any;
}

interface S {
    // Customizable Area Start
    anchorEl: null | HTMLElement;
    orderDetails :null | IDeliveryChallanItem

    // Customizable Area End
}

export type Props = RouteComponentProps<{ orderId: string }> & {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    userType: any;
    userProfile: any;
    setUserProfile: any;
    setError: any;
    newNotification:boolean
    setNewNotification:React.Dispatch<React.SetStateAction<boolean>>
    setLenghtOfNotification:React.Dispatch<React.SetStateAction<number>>
    lengthOfNotification:number
    // Customizable Area End
}

export default class DeliveryChallanOrderStatusController extends BlockComponent<
    Props,
    S,
    SS
>{

    getDeliveryChallanOrderStatusApiCallId:string=""
    updateDeliveryChallanStatusApiCallId:string=""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            // Customizable Area Start
            anchorEl: null,
            orderDetails : null

            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let deliveryChallanOrderStatusApiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const deliveryChallanOrderStatusResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (deliveryChallanOrderStatusResponseJson) {
                if (deliveryChallanOrderStatusResponseJson.status === 500) {
                    this.props.setError("Internal Server Error");
                    return;
                }
                else if (deliveryChallanOrderStatusResponseJson.errors) {
                    console.log("####errors", deliveryChallanOrderStatusResponseJson.errors);
                }
                else {
                    this.handleSuccessCallbacks(deliveryChallanOrderStatusApiCallId,deliveryChallanOrderStatusResponseJson)
                }
            }
        }
    }

    async componentDidMount() {
        const { params } = this.props.match
        if (params.orderId) {
           this.getOrderDetails(params.orderId)
        }
    }

    handleSuccessCallbacks = (apiRequestCallId:string, responseJson:any)=>{
        if(apiRequestCallId === this.getDeliveryChallanOrderStatusApiCallId){
            this.setState({orderDetails:responseJson?.data})
        }
        else if(apiRequestCallId === this.updateDeliveryChallanStatusApiCallId){
            if(this.state.orderDetails){
                this.getOrderDetails(String(this.state.orderDetails?.attributes.id))
            }
            this.handleClose()

        }
    }
    

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    getOrderDetails = (orderId:string)=>{
    this.getDeliveryChallanOrderStatusApiCallId = this.deliveryChallanOrderStatusApiCall({
        contentType:configJSON.contentTypeApplicationJson,
        method: configJSON.getAPIMethod,
        endPoint: `${configJSON.getDeliveryChallanOrderDetailsEndPoint}/${orderId}`,
        })
    }

    updateOrderStatus = (orderId:string| number,status:string) =>{
        const body = { "delivery_challan": {
            "status": status
          }}
        this.updateDeliveryChallanStatusApiCallId = this.deliveryChallanOrderStatusApiCall({
            contentType:configJSON.contentTypeApplicationJson,
            method: configJSON.putAPIMethod,
            endPoint: `${configJSON.updateDeliveryChallanOrderStatusEndPoint}/${orderId}`,
            body
        })
    }

    deliveryChallanOrderStatusApiCall = (data: any) => {
        const { contentType, method, endPoint, body, type} = data;
        console.log("api call",contentType, method, endPoint,body)
        let token = window.localStorage.getItem("token");
        let header:Record<string,string|undefined|null> = {
          token,
        };
        if(contentType){
          header = {
            ...header,
            "Content-Type":contentType
          }
        }
        const deliveryChallanOrderStatusApiMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        deliveryChallanOrderStatusApiMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        deliveryChallanOrderStatusApiMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        deliveryChallanOrderStatusApiMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        deliveryChallanOrderStatusApiMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          type === "form-data"? body : JSON.stringify(body) 
        );
        runEngine.sendMessage(deliveryChallanOrderStatusApiMessage.id, deliveryChallanOrderStatusApiMessage)
        return deliveryChallanOrderStatusApiMessage.messageId;
      }

}
