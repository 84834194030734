
import React from "react";
// Customizable Area Start
import { withRouter } from "react-router-dom";
import InventoryDetailsController,{ Props } from "./InventoryDetailsController.web";
import { Grid, Checkbox, FormControl, FormLabel, InputAdornment, TextField, withStyles, Typography } from "@material-ui/core";
import DarkStoreDashboardLayout from "../../../../components/src/DarkStoreDashboardLayout.web";
import ImageIcon from '@material-ui/icons/Image';
import NoAccessPage from "../../../../components/src/NoAccessPage.web";

const styles : any = {
    container: {
        margin: "24px",
        padding: "24px 30% 24px 24px",
        background: "#fff",
        borderRadius: "10px",
        "& p": {
            fontFamily: "gilroy-bold !important"
        },
        "& h6":{
            fontFamily: "gilroy-medium !important",
            fontWeight: "600",
            fontSize: "14px",
            marginBottom:"12px",
            marginLeft:"8px"
        }
    },
    itemsContainer:{
        display:"inline-flex",
        padding:"12px 0"
    },
    itemPicture:{
        padding:"12px",
        border:"1px solid #EFEFEF",
        borderRadius:"8px",
        "& img, svg":{
            height:"70px",
            width:"60px"
        }
    },
    startAdornment: {
        "& p": {
            color: "black !important"
        }
    },
    formControl: {
        marginBottom: "12px",
        padding: "0 8px 0",
        "& label": {
            textAlign: "start",
            color: "black",
            fontWeight: "600",
            fontSize: "14px",
        },
        " & input": {
            fontFamily: "gilroy-regular",
            fontSize: "14px !important"
        },
        "& fieldset":{
            borderRadius:"12px",
        },
        "& a, a:hover, a:active": {
            textDecoration: "none",
            marginRight: "0px",
            marginLeft: "auto",
            color: "rgba(0, 0, 0, 0.64)",
            "& h6": {
                paddingBottom: "0px !important",
                fontSize: "14px !important"
            }
        },
        "& p": {
            color: "red"
        }
    },
    gridItem: {
        display: "flex",
        flexFlow: "column"
    },
}

class InventoryDetails extends InventoryDetailsController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { classes, ...restProps } = this.props;
        const { inventoryDetails } = this.state;

        return (
            <DarkStoreDashboardLayout {...restProps} headerAction="New Item" headerTitle="Inventory Management" actionHandler={undefined} subTitle="" hasAccess={true}>
                {inventoryDetails ? (
                    <>
                        <div className={classes.container}>
                            <Grid container>
                                <Grid item className={classes.gridItem} sm={12} xs={12} xl={12} lg={12} md={12}>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <FormLabel>Product Image</FormLabel>
                                        <div className={classes.itemsContainer}>
                                            {inventoryDetails?.attributes?.images?.length > 0 ? inventoryDetails.attributes.images.map((img: any) => <div className={classes.itemPicture}>
                                                <img src={img.url} />
                                            </div>) : <div className={classes.itemPicture}><ImageIcon /></div>}
                                        </div>
                                    </FormControl>
                                </Grid>
                                <Grid item className={classes.gridItem} sm={12} xs={12} xl={12} lg={12} md={12}>
                                    <FormControl fullWidth className={classes.formControl} >
                                        <FormLabel required>Display Location</FormLabel>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={inventoryDetails?.attributes?.display_location}
                                            placeholder=""
                                            margin="normal"
                                            disabled />
                                    </FormControl>
                                </Grid>
                                <Grid item className={classes.gridItem} sm={12} xs={12} xl={12} lg={12} md={12}>
                                    <FormControl fullWidth className={classes.formControl} >
                                        <FormLabel required>Product Name</FormLabel>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={inventoryDetails?.attributes?.name}
                                            placeholder=""
                                            margin="normal"
                                            disabled />
                                    </FormControl>
                                </Grid>
                                <Grid item className={classes.gridItem} sm={12} xs={12} xl={12} lg={12} md={12}>
                                    <FormControl fullWidth className={classes.formControl} >
                                        <FormLabel required>Brand</FormLabel>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={inventoryDetails?.attributes?.brand?.name}
                                            placeholder=""
                                            margin="normal"
                                            disabled />
                                    </FormControl>
                                </Grid>
                                <Grid item className={classes.gridItem} sm={12} xs={12} xl={12} lg={12} md={12}>
                                    <FormControl fullWidth className={classes.formControl} >
                                        <FormLabel required>Categories</FormLabel>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={inventoryDetails?.attributes?.category?.name}
                                            placeholder=""
                                            margin="normal"
                                            disabled />
                                    </FormControl>
                                </Grid>
                                <Grid item className={classes.gridItem} sm={12} xs={12} xl={12} lg={12} md={12}>
                                    <FormControl fullWidth className={classes.formControl} >
                                        <FormLabel required>Sub Categories</FormLabel>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={inventoryDetails?.attributes?.sub_category?.name}
                                            placeholder=""
                                            margin="normal"
                                            disabled />
                                    </FormControl>
                                </Grid>
                                <Grid item className={classes.gridItem} sm={12} xs={12} xl={12} lg={12} md={12}>
                                    <FormControl fullWidth className={classes.formControl} >
                                        <FormLabel required>Description</FormLabel>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={inventoryDetails?.attributes?.description}
                                            placeholder=""
                                            margin="normal"
                                            multiline

                                            disabled />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.container}>
                            <Typography variant="h6">Sales Information</Typography>
                            <Grid container>
                                <Grid item className={classes.gridItem} sm={6} xs={6} xl={6} lg={6} md={6}>
                                    <FormControl fullWidth className={classes.formControl} >
                                        <FormLabel required>Selling Price</FormLabel>
                                        <TextField
                                            variant="outlined"
                                            InputProps={{ startAdornment: <InputAdornment className={classes.startAdornment} position="start">₹</InputAdornment> }}
                                            fullWidth
                                            value={inventoryDetails?.attributes?.sale_price}
                                            placeholder=""
                                            margin="normal"
                                            disabled />
                                    </FormControl>
                                </Grid>
                                <Grid item className={classes.gridItem} sm={6} xs={6} xl={6} lg={6} md={6}>
                                    <FormControl fullWidth className={classes.formControl} >
                                        <FormLabel>Discounted Price</FormLabel>
                                        <TextField
                                            InputProps={{ startAdornment: <InputAdornment className={classes.startAdornment} position="start">₹</InputAdornment> }}
                                            variant="outlined"
                                            fullWidth
                                            value={inventoryDetails?.attributes?.discount_price}
                                            placeholder=""
                                            margin="normal"
                                            disabled />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item className={classes.gridItem} sm={6} xs={6} xl={6} lg={6} md={6}>
                                    <FormControl fullWidth className={classes.formControl} >
                                        <FormLabel required>Net Weight</FormLabel>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={inventoryDetails?.attributes?.weight}
                                            placeholder=""
                                            margin="normal"
                                            disabled />
                                    </FormControl>
                                </Grid>
                                <Grid item className={classes.gridItem} sm={6} xs={6} xl={6} lg={6} md={6}>
                                    <FormControl fullWidth className={classes.formControl} >
                                        <FormLabel required>Unit</FormLabel>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={inventoryDetails?.attributes?.weight_unit}
                                            placeholder=""
                                            margin="normal"
                                            disabled />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item className={classes.gridItem} sm={6} xs={6} xl={6} lg={6} md={6}>
                                    <FormControl fullWidth className={classes.formControl} >
                                        <FormLabel required>Quantity</FormLabel>
                                        <TextField
                                            type="number"
                                            variant="outlined"
                                            fullWidth
                                            value={inventoryDetails?.attributes?.stock_qty}
                                            placeholder=""
                                            margin="normal"
                                            disabled />
                                    </FormControl>
                                </Grid>
                                <Grid item className={classes.gridItem} sm={6} xs={6} xl={6} lg={6} md={6}>
                                    <FormControl fullWidth className={classes.formControl} >
                                        <FormLabel required>Inventory Alert</FormLabel>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={inventoryDetails?.attributes?.alert_quantity}
                                            placeholder=""
                                            margin="normal"
                                            type="number"
                                            disabled />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item className={classes.gridItem} sm={12} xs={12} xl={12} lg={12} md={12}>
                                    <FormControl fullWidth className={classes.formControl} >
                                        <FormLabel required>HSN Code</FormLabel>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={inventoryDetails?.attributes?.hsn_code}
                                            placeholder=""
                                            margin="normal"
                                            disabled />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item className={classes.gridItem} sm={6} xs={6} xl={6} lg={6} md={6}>
                                    <FormControl fullWidth className={classes.formControl} >
                                        <FormLabel required>Bulk Order price</FormLabel>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={inventoryDetails?.attributes?.bulk_order_price}
                                            InputProps={{ startAdornment: <InputAdornment className={classes.startAdornment} position="start">₹</InputAdornment> }}
                                            placeholder=""
                                            margin="normal"
                                            type="number"
                                            disabled />
                                    </FormControl>
                                </Grid>
                                <Grid item className={classes.gridItem} sm={6} xs={6} xl={6} lg={6} md={6}>
                                    <FormControl fullWidth className={classes.formControl} >
                                        <FormLabel required>Minimum purchase quantity</FormLabel>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={inventoryDetails?.attributes?.bulk_order_min}
                                            placeholder=""
                                            margin="normal"
                                            type="number"
                                            disabled />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item className={classes.gridItem} sm={12} xs={12} xl={12} lg={6} md={6}>
                                    <FormControl fullWidth className={classes.formControl} >
                                        <FormLabel><Checkbox checked={inventoryDetails?.attributes?.is_bulk || false} /> Bulk Order </FormLabel>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </div>
                    </>
                ) : (
                    <NoAccessPage description="Something went wrong" />
                )}
            </DarkStoreDashboardLayout>
        )
        // Customizable Area Start
    }
}

// Customizable Area Start
export { InventoryDetails };
export default withStyles(styles)(withRouter(InventoryDetails));
// Customizable Area End
